import { Component, Input } from '@angular/core'
import { UntypedFormGroup } from '@angular/forms'
import { ProficloudInputConfig } from '../type-definitions/types'

@Component({
  selector: 'pc-input-base',
  template: '',
  styles: [],
})
export abstract class PcInputBaseComponent {
  @Input()
  form?: UntypedFormGroup

  @Input()
  config?: ProficloudInputConfig

  @Input()
  dependsOn?: string | string[]
}
