import { Pipe, PipeTransform } from '@angular/core'
import { UntypedFormGroup } from '@angular/forms'
import { ProficloudInputConfig } from '@shared/type-definitions/types'

@Pipe({
  name: 'inputIsActive',
  pure: false,
})
export class InputIsActivePipe implements PipeTransform {
  transform(form: UntypedFormGroup | undefined, ...args: unknown[]): boolean {
    const config = args[0] as ProficloudInputConfig
    if (!config) {
      return true
    }

    if (config?.dependsOn && form) {
      if (typeof config?.dependsOn === 'string') {
        return form?.controls[config?.dependsOn].valid
      }
      if (Array.isArray(config?.dependsOn)) {
        return config?.dependsOn.map((d) => form?.controls[d].valid).reduce((a, b) => a && b, true)
      }
    }

    return true
  }
}
