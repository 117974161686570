import { HttpErrorResponse } from '@angular/common/http'
import { Subscription } from 'rxjs'
import { ContentfulPlanVariant, ContentfulService } from './billing/contentful-interfaces'

export type ExpansionStates = 'collapsed' | 'expanded'

export interface MultiStatusOverlayContent {
  status: { icon: string; message: string }[]
  closeable?: boolean
  extraInfo?: string
  auto_close?: boolean
  title?: string
}

export class Overlay {
  message: string

  icon: string
}

export interface KeycloakData {
  session?: SessionData
  access_token?: KeycloakJWT
  refresh_token?: KeycloakJWT
  userDetails?: UserDetailsResponse
}

export class LoginResponse {
  data: SessionData | MiddlewareError
}

export interface RefreshData {
  access_token: string
  expires_in: number
  id_token: string
  'not-before-policy': number
  refresh_expires_in: number
  refresh_token: string
  scope: 'openid profile email'
  session_state: string
  token_type: 'bearer'
}

export interface RefreshResponse {
  status_code: number
  data: RefreshData
}

export class MiddlewareError {
  error: string

  error_description: string
}

export class SessionData {
  access_token: string | null
  refresh_token: string | null
}

export class SSOSessionData {
  access_token: string | null
  refresh_token: string | null
}

export type UserAttributeValues =
  | 'PKIready'
  | 'locale'
  | 'country'
  | 'roles'
  | 'feature_flag_mms'
  | 'feature_flag_emma'
  | 'feature_flag_dms'
  | 'feature_flag_emob'
  | 'emma_simulator'
  | 'AdminService'

export type AdminServiceAttributeValues = 'uuid' | 'dt' | 'fw' | 'true'

export interface UserDetailsResponse {
  data: {
    attributes?: {
      country: [string]
      krn: [string]
      locale?: [string]
      path: [string]
      termsAndConditions: [string]
      PKIready?: ('true' | 'false')[]
      roles?: string[]
      feature_flag_mms?: string[]
      feature_flag_emma?: string[]
      feature_flag_dms?: string[]
      feature_flag_emob?: string[]
      emma_simulator?: string[]
      AdminService?: AdminServiceAttributeValues[]
    }
    created: string
    email: string
    id: string
    disableableCredentialTypes: string[]
    emailVerified: boolean
    firstName: string
    keycloakUserId: string
    krn: string
    lastName: string
    path: [string]
    tenantID: string
    updated: string
    userId: string
    username: string
  }
}

export class UserInvitationResponse {
  data: {
    existingUser: boolean
    emailSent: boolean
    registrationLink: string
  }
}

export interface KeycloakJWT {
  activeOrganization: string
  jti: string
  exp: number
  nbf: number
  iat: number
  iss: string
  aud: string
  sub: string
  typ: string
  azp: string
  auth_time: number
  session_state: string
  acr: string
  'allowed-origins': string[]
  realm_access: RealmAccess
  resource_access: {}
  scope: string
  email_verified: boolean
  preferred_username: string
  locale: string
  email: string
}

export interface RealmAccess {
  roles: string[]
}

export type DateRangeStrings = {
  start: string
  end: string
}

export type Environments = 'dev' | 'staging' | 'production' | 'local'

export interface ImpulsePackagesResponseItem {
  id?: string
  name?: string
  bookableMultipleTimes: boolean
  chargingType: string
  contractTerms: {
    billingPeriod: string
    cancellationPeriod: string
    contractPeriod: string
    feePeriod: string
    trialPeriod: {
      quantity: number
      unit: string
    }
    description: string
    extras: any
    id: string
    maxNumberOfDevices: number
    name: string
  }
  pricing: {
    currency: string
    recurringFee: number
    setupFee: number
  }
  smartServiceId: string
}

export type ImpulsePackagesResponse = ImpulsePackagesResponseItem[]

export interface TsdPackagesResponseItem {
  bookableMultipleTimes: boolean
  chargingType: string
  contractTerms: {
    billingPeriod: string
    cancellationPeriod: string
    contractPeriod: string
    feePeriod: string
    trialPeriod: boolean
  }
  description: string
  extras: any
  id: string
  maxNumberOfMetrics: number
  name: string
  pricing: {
    currency: string
    recurringFee: number
    setupFee: number
  }
  recurringFeePerMonth: number
  smartServiceId: string
}

export type TsdPackagesResponse = TsdPackagesResponseItem[]

export type ServiceSubscription = TsdSubscription | IaSubscription

export interface UpgradePreview {
  newSubscription: {
    net: number
    package: BookingPackage
    quantity: number
  }
  oldSubscription: {
    net: number
    package: BookingPackage
    quantity: number
  }
  subscription: ServiceSubscription
}
export interface SmartService {
  id: string
  name: string
  description: string
  bookablePackages: BookingPackage[]
}

export interface BookingPackage {
  id: string
  name: string
  description: string
  chargingType: string
  isBookableMultipleTimes: boolean
  pricing: {
    recurringFee: number
    setupFee: number
    currency: string
  }
  contractTerms: ContractTerms
  quantity: number
  extras: Map<string, string>
}

export interface SimplePackage {
  packageId: string
  quantity: number
  sendNoConfirmationMail?: boolean
  stripeSuccessUrl?: string
  stripeCancelUrl?: string
  // coming up: free-text reference
  internalOrderId?: string
}

export interface ContractTerms {
  contractPeriod: TimeUnit
  cancellationPeriod: TimeUnit
  feePeriod: TimeUnit
  trialPeriod: TrialPeriod
  billingPeriod: TimeUnit
}

export interface TrialPeriod {
  quantity: number
  unit: TimeUnit
}

export type TimeUnit = [year, month, null]
export type year = number
export type month = number

export interface BillingAccount {
  readonly firstName: string
  readonly lastName: string
  readonly language: string
  readonly companyName: string
  readonly emailAddress: string
  readonly vatId: string
  readonly pxcCustomerId: string
  readonly address: BillingAddress
}

export interface BillingAddress {
  street: string | undefined
  houseNumber: string | undefined
  addressLine1: string
  addressLine2: string
  city: string
  postalCode: string
  state: string
  country: AcceptableBillingCountryCodes
}

export interface ShoppingCart {
  service?: ContentfulService
  plan?: ContentfulPlanVariant
  quantity?: number
}

export interface SubscriptionResponse {
  readonly id: string
  readonly bookedPackageId: string
  readonly startDate: string
  readonly endDate: string
  readonly quantity: number
  readonly isInTrialPeriod: boolean
  readonly internalOrderId: string // user submitted reference
  readonly referenceCode: string //  contract reference, e.g. "LZPJT-HYLDX"
  readonly smartServiceId: ServiceIds
  readonly trialEndDate?: string
  readonly stripeCheckoutUrl?: string
}

export interface CreatedSubscription {
  readonly id: string
  readonly smartServiceId: ServiceIds
  readonly bookedPackageId: string
  readonly startDate: string
  readonly endDate: string | null
  readonly trialEndDate: string | null
  readonly quantity: number
  readonly referenceCode: string
  readonly internalOrderId: string | null
  readonly nextSubscriptionPhase: string | null // Check this
  readonly inTrialPeriod: boolean
}

export interface CreateSubscriptionResponse {
  readonly id: string
  readonly smartServiceId: ServiceIds
  readonly bookedPackageId: string
  readonly status: string // 'COMMITTED' and not sure what else
  readonly internalOrderId: string // user submitted reference
  readonly stripeCheckoutUrl: string | null
  readonly subscription: CreatedSubscription
}

export interface IaSubscription extends SubscriptionResponse {
  readonly devicesUsed: string[]
  // added
  expanded?: boolean
  showCancelOption: boolean
}

// alex proxy sub
export interface TsdSubscription extends SubscriptionResponse {
  readonly smartServiceId: 'tsd'
  readonly metricsUsed: MetricsUsed[]
  // added
  expanded?: boolean
  showCancelOption?: boolean
}

export type MetricsUsed = Record<string, string[]>

export interface DMSDeviceMapping {
  deviceId: string
  deviceMetricName: string
  id: string
  metricId: string
}

export type ServiceIds = 'tsd' | 'ia' | 'emma' | 'dmsAddonBasic' | 'tsd-prologis' | 'chargeRepay' | 'ems'

export type LongServiceIds = 'tsd' | 'impulseanalytics' | 'emma'

export type UserFormData = {
  firstName: string
  lastName: string
  email?: string
  locale?: string[]
}

export type AvailableEndpointMetricsResponse = Record<string, string[]>

export type AnchorElementNames = 'emmaAnchor' | 'impulseAnalyticsAnchor'

export type MobileControls = 'map' | 'search' | 'sort'

export type AcceptableBillingCountryCodes = 'DE' | 'CH' | 'IT' | 'ES' | 'CL' | 'US'

export type HttpHeader = {
  Authorization?: string
  'API-Secret'?: string
  'Content-Type': string
  Accept: string
  'Access-Control-Allow-Headers': string
  'X-Security-Assume'?: string
}

/*
  Roles coming from IAM. There are only a few predefined roles available at the moment.
  Will be enhanced by new and more detailed roles
 */
export enum OrganisationRole {
  ROLE_VIEWER = 'ROLE_VIEWER',
  ROLE_EDITOR = 'ROLE_EDITOR',
  ROLE_ADMIN = 'ROLE_ADMIN',
}

export interface Organisation {
  organizationId: string
  organizationName: string
  organizationDetails?: OrganisationDetails
  rbacSettings?: {
    defaultAccess: 'allow' | 'deny'
  }
  userRole: OrganisationRole
  expansionState?: ExpansionStates
}

export interface OrganisationDetails {
  organizationId: 'string'
  organizationName: 'string'
  memberCount: number
  organizationCreator: {
    userId: 'string'
    email: 'string'
    firstName: 'string'
    lastName: 'string'
  }
}

export interface OrganisationsResponse {
  data: Organisation[]
}

export interface OrganisationDetailResponse {
  data: OrganisationDetails
}

export interface Member {
  user: {
    email: string
    firstName: string
    lastName: string
    userId: string
    created: string
    expansionState?: ExpansionStates
  }
  userRole: OrganisationRole
}

// TODO can be deleted once it got moved to user management module
export interface Invitation {
  createdBy: string
  createdById: string
  createdTime: string
  id: string
  message: string
  organizationId: string
  organizationName: string
  status: string
  statusChangeTime: string
  securityToken: string
  userEmail: string
  userId: string
  userRole: string
  invitationLink: string
  expansionState?: ExpansionStates
}

export interface OrganisationMembersResponse {
  data: {
    memberCount: number
    members: Member[]
  }
}

export interface ApiResponseV_1_2<T = any> {
  apiVersion: string
  id: string
  context?: string
  method?: string
  data?: T
  error?: any
}

export type OrganisationTabs = 'information' | 'subscriptions' | 'billing' | 'users'

export declare interface ProficloudController {
  organisationSwitchedSubscription: Subscription
}

export interface IamUserResponse {
  data: {
    created: string
    email: string
    firstName: string
    lastName: string
    updated: string
    userId: string
  }
}

export interface IamCreateUser {
  firstName: string
  lastName: string
  email: string
  termsAndConditions: 'Accepted' | 'Rejected'
  password: string
  country: string
  invitationToken: string | undefined
  organizationName: string
  locale: 'en-GB'
  redirectUri: string
}

export type NotificatioNCapableServices = 'emma' | 'emma-reporting' | 'device-management' | 'user-management' | 'unknown'

export type NotificationPriority = 'info' | 'warning' | 'alert'

export interface IServiceNotification {
  id: string
  triggerTS: string
  responsible: NotificatioNCapableServices
  priority: NotificationPriority
  description: string
}

export interface StreamedDeviceNotification extends IServiceNotification {}

export interface StreamedUserNotification extends IServiceNotification {}

export interface SystemNotification {
  serviceNotification: IServiceNotification
  dismissed: boolean
  received: Date
  persisted?: boolean
}

export interface StandardAPIError {
  error: {
    details: any[]
    id: string
    message: string
    timestamp: string
  }
}

// Note: Not currently able to make this any more specific as we don't have regex in TS yet
export type DateRangeToken = string

export type ValidPeriods = 'D' | 'W' | 'M' | 'Y'

export interface BillingStoreErrorState {
  billingAccount?: HttpErrorResponse
  subscriptions?: HttpErrorResponse
  packages?: HttpErrorResponse
  endpointMetrics?: HttpErrorResponse
}
