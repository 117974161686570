@if (form | inputIsActive: config) {
  <mat-form-field class="input-chiplist input-chiplist--fit-width" [class.input-chiplist--fit-width]="variant === 'fit-width'">
    <mat-label [attr.data-analytics]="completeId('-label')">{{ chipsField.placeholder ? (chipsField.placeholder | translate) : '' }}</mat-label>
    <mat-chip-grid
      [attr.data-analytics]="completeId('-placeholder')"
      #chipGrid
      aria-label="{{ chipsField.placeholder ? (chipsField.placeholder | translate) : '' }}">
      @for (tag of chipsField.value; track tag) {
        <mat-chip-row (removed)="removeTag(tag)" [attr.data-analytics]="completeId('-tag')">
          {{ tag }}
          <button matChipRemove [attr.aria-label]="'remove ' + tag">
            <mat-icon [attr.data-analytics]="completeId('-cancel')">cancel</mat-icon>
          </button>
        </mat-chip-row>
      }
      <input
        matInput
        [formControl]="chipsField.control"
        [placeholder]="chipsField.placeholder ? (chipsField.placeholder | translate) : ''"
        [matChipInputFor]="chipGrid"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="addTag($event.value)"
        [attr.data-analytics]="completeId('-input')" />
    </mat-chip-grid>
    @if (hint) {
      <mat-hint [attr.data-analytics]="completeId('-hint')">{{ hint }}</mat-hint>
    }
    @if (((!control?.errors && control?.touched) || cross) && !reset) {
      <app-icon
        class="input-chiplist__icon input-chiplist__icon--reset"
        [class.input-chiplist__icon--small]="size === 'small'"
        color="var(--primary)"
        name="cross"
        matSuffix
        (click)="deleteInput()"
        [attr.data-analytics]="completeId('-delete')"></app-icon>
    }
    @if (reset && resetActivated) {
      <app-icon
        class="input-chiplist__icon input-chiplist__icon--reset"
        [class.input-chiplist__icon--small]="size === 'small'"
        color="var(--primary)"
        name="reset"
        matSuffix
        (click)="resetAll()"
        [attr.data-analytics]="completeId('-reset')"></app-icon>
    }
  </mat-form-field>
}
