import { inject, Injectable } from '@angular/core'
import { StatusOverlayService } from '@dms/services/status-overlay.service'
import { TranslateService } from '@ngx-translate/core'
import { PCStatusOverlayConfig } from '@shared/type-definitions/types'
import { isString } from 'lodash'
import { Subject, tap } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class PcStatusOverlayService {
  private statusBin = inject(StatusOverlayService)

  public config?: PCStatusOverlayConfig

  private loadingStatusStack: {
    key: string
    config: PCStatusOverlayConfig | false // Note: only with type 'spinner' should be here
  }[] = []

  private resolutionStatusList: {
    key: string
    config: PCStatusOverlayConfig | false // Note: none with type 'spinner' should be here
  }[] = []

  public queueLoadingStatus$: Subject<{ key: string; config: PCStatusOverlayConfig }> = new Subject()
  public resolveLoadingStatus$: Subject<{ key: string; config: PCStatusOverlayConfig | false }> = new Subject()

  constructor(private translationService: TranslateService) {
    /**
     *
     * NEW SUBJECTS AND METHODS FOR SHOWING AND HIDING MULTIPLE STATUS MESSAGES
     */

    // Loading status stack
    this.queueLoadingStatus$
      .pipe(
        tap((item) => {
          // Put new loading statuses at the front of the list
          this.loadingStatusStack.unshift(item)
        }),
        tap(() => {
          this.showRelevantQueuedStatus()
        })
      )
      .subscribe()

    // Resolution status
    this.resolveLoadingStatus$
      .pipe(
        tap((resolution) => {
          // Always remove matched loading
          this.removeLoadingStatus(resolution.key)

          // If we have a blank resolution then we're done
          if (resolution.config === false) {
            return
          }

          this.resolutionStatusList.push(resolution)

          if (resolution.config.type === 'success') {
            // TODO: Not sure if this is nice enough, maybe there's some way we can do it declaratively
            setTimeout(() => {
              this.removeResolutionStatus(resolution.key)
              this.showRelevantQueuedStatus()
            }, 1000)
          }
        }),
        tap(() => {
          this.showRelevantQueuedStatus()
        })
      )
      .subscribe()
  }

  private showRelevantQueuedStatus() {
    // Put the relevant config onto the displayed config
    // 1. If both queues are empty then clear it
    // 2. If there is only a resolution then show it
    // 3. If there is an error then show it
    // 4. If there are no errors then show a loading in front of a resolution

    if (this.resolutionStatusList.length) {
      this.config = this.resolutionStatusList[0].config as PCStatusOverlayConfig
      return
    }

    if (this.loadingStatusStack.length) {
      this.config = this.loadingStatusStack[0].config as PCStatusOverlayConfig
      return
    }

    delete this.config
  }

  private removeLoadingStatus(key: string) {
    // Note: Clear all just in case there are more for some reason
    while (this.loadingStatusStack.find((c) => c.key === key)) {
      const i = this.loadingStatusStack.findIndex((c) => c.key === key)
      this.loadingStatusStack.splice(i, 1)
    }
  }

  private removeResolutionStatus(key: string) {
    const i = this.resolutionStatusList.findIndex((c) => c.key === key)
    this.resolutionStatusList.splice(i, 1)
  }

  /**
   *
   * OLD METHODS FOR JUST SHOWING/HIDING A SINGLE STATUS MESSAGE
   */

  // Presents the status based on the provided configuration
  public showStatus(config: PCStatusOverlayConfig | string) {
    if (isString(config)) {
      this.config = this.statusBin.statusOverlays[config]
    } else {
      this.config = config
    }

    // check timeout for success messages only
    if (this.config?.type === 'success' && !this.config?.preventAutoClose) {
      this.closeAfterTimeout(this.config.timeout)
    }
  }

  public showStatusTranslate(configInc: PCStatusOverlayConfig, overlay?: string) {
    this.translationService.get(configInc.message).subscribe((trnsl) => {
      if (!trnsl) {
        throw new Error('Translation failed. Not found.')
      }

      const translatedConfig: PCStatusOverlayConfig = configInc
      translatedConfig.message = trnsl

      this.config = translatedConfig

      // check timeout for success messages only
      if (this.config.type === 'success') {
        this.closeAfterTimeout(this.config.timeout)
      }
    })
  }

  // Manually close the overlay using the cross icon on the template
  public closeStatus() {
    // On a manual close (which is usuall an error) we can clear all the resolution statuses, if there are any,
    // because we don't want to present the user with a big list of errors, one is enough really.
    this.loadingStatusStack.length = 0
    this.resolutionStatusList.length = 0
    delete this.config
  }

  // Resets the status programatically
  // Note: This will not work if the current status is an error because we don't want these to be ignored
  public resetStatus() {
    if (this.config?.type !== 'error') {
      delete this.config
    }
  }

  // closes the status by resetting the config after a period of time
  // default 2 Seconds
  private closeAfterTimeout(ms: number = 2000) {
    setTimeout(() => {
      delete this.config
    }, ms)
  }
}
