import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { ProficloudService } from '@services/proficloud.service'
import { UiService } from '@services/ui/ui.service'
import { SelectConfig, SelectGroup, SelectGroupedItems, SelectSubItem } from '@shared/type-definitions/types'

@Component({
  selector: 'app-proficloud-select',
  templateUrl: './proficloud-select.component.html',
  styleUrls: ['./proficloud-select.component.scss', '../../styles/shared-styles.scss'],
})
export class ProficloudSelectComponent<T = string> implements OnInit {
  hasFocus = false

  expanded = false

  allSelected = false

  hasGroups = false

  selectedValue: SelectSubItem<T>[] = []

  filterCriteria: string

  contentLeft = '0px'

  contentTop = '0px'

  contentWidth = '200px'

  filterControl = new UntypedFormControl('', [])

  @Input()
  form: UntypedFormGroup

  @Input()
  options: SelectConfig<T>

  @Input()
  afterExpand: () => void

  @Input()
  afterClickedOutside: () => void

  @ViewChild('selectContainer')
  selectContainer: ElementRef

  @ViewChild('container')
  container: ElementRef

  @ViewChild('filterInput')
  filterInput: ElementRef

  constructor(
    public proficloud: ProficloudService,
    private ui: UiService
  ) {}

  ngOnInit(): void {
    this.hasGroups = !Array.isArray(this.options.items)

    // Construct options object
    if (Array.isArray(this.options.items) && this.options.hasAll) {
      this.hasGroups = true
      const groupedItems: SelectGroupedItems<T> = {
        all: {
          label: 'All',
          checked: 0,
          values: this.options.items,
        },
      }
      this.options.items = groupedItems
    }

    // Set initial value if we have one
    if (this.options.multi) {
      if (this.options.control.value && this.options.control.value.length) {
        if (Array.isArray(this.options.control.value)) {
          const initialSubItems = this.options.control.value.map((value) => this.getSubItem(value))
          initialSubItems.forEach((item) => {
            if (item) {
              this.subItemClicked(item, false, undefined, true)
            }
          })
        } else {
          const item = this.options.control.value
          if (item) {
            this.subItemClicked(item, false, undefined, true)
          }
        }
      }
    } else {
      // Single string
      if (this.options.control.value && this.options.control.value.length) {
        const value = Array.isArray(this.options.control.value) ? this.options.control.value[0] : this.options.control.value
        const initialSubItem = this.getSubItem(value)
        if (initialSubItem) {
          this.subItemClicked(initialSubItem, false)
        }
      }
    }
  }

  private getSubItem(key: string) {
    let found: SelectSubItem<T> | undefined

    // Check if we have groups
    if (!Array.isArray(this.options.items)) {
      Object.keys(this.options.items)
        .map((k) => (this.options.items as SelectGroupedItems<T>)[k])
        .forEach((group: SelectGroup<T>) => {
          group.values.forEach((subItem) => {
            if (subItem.key === key) {
              found = subItem
            }
          })
        })
    } else {
      this.options.items.forEach((subItem) => {
        if (subItem.key === key) {
          found = subItem
        }
      })
    }
    return found
  }

  private applyToAllItems(f: (item: SelectSubItem<T>) => void) {
    if (Array.isArray(this.options.items)) {
      this.options.items.forEach(f)
    } else {
      Object.keys(this.options.items)
        .map((k) => (this.options.items as SelectGroupedItems<T>)[k])
        .forEach((group: SelectGroup<T>) => {
          group.values.forEach((subItem) => {
            f(subItem)
          })
        })
    }
  }

  private updateSelectedValue(controlSetRequired = true) {
    this.selectedValue.length = 0
    this.applyToAllItems((subItem) => {
      if (subItem.checked) {
        this.selectedValue.push(subItem)
      }
    })

    if (controlSetRequired) {
      this.options.control.setValue(this.selectedValue.map((sv) => sv.key))
    }

    if (this.options.onChange) {
      this.options.onChange(this.selectedValue.map((sv) => sv.key))
    }
  }

  public groupItemClicked(value: SelectGroup) {
    if (!this.options.multi) {
      return
    }

    if (value.values.filter((v) => v.visible && v.checked && !v.excluded).length === value.values.filter((v) => v.visible && !v.excluded).length) {
      value.values.filter((v) => v.visible && !v.excluded).forEach((v) => (v.checked = false))
    } else {
      value.values.filter((v) => v.visible && !v.excluded).forEach((v) => (v.checked = true))
    }

    this.updateSelectedValue()
    this.expand(true)
  }

  public subItemClicked(subItem: SelectSubItem<T>, controlSetRequired = true, event?: MouseEvent, preventExpansion = false) {
    if (this.options.multi) {
      subItem.checked = !subItem.checked
      if (!preventExpansion) {
        this.expand(true)
      }
    } else {
      this.applyToAllItems((s) => {
        s.checked = false
      })
      subItem.checked = true
      this.expanded = false
      this.clearFilterCriteria()
    }
    this.updateSelectedValue(controlSetRequired)
    if (event) {
      event.preventDefault()
    }
  }

  public groupItemStatus(value: SelectGroup) {
    const visibleCount = value.values.filter((v) => v.visible).length
    const checkedCount = value.values.filter((v) => v.visible && v.checked).length
    return checkedCount === visibleCount ? 2 : checkedCount > 0 ? 1 : 0
  }

  public remove(value: SelectSubItem, event: MouseEvent) {
    value.checked = false
    this.selectedValue.splice(this.selectedValue.map((v) => v.key).indexOf(value.key), 1)
    this.options.control.setValue(this.selectedValue.map((sv) => sv.key))

    if (this.options.onChange) {
      this.options.onChange(this.selectedValue.map((sv) => sv.key))
    }

    this.expand(true)
    event.stopPropagation()
  }

  public clearSelection() {
    this.applyToAllItems((subItem) => {
      subItem.checked = false
    })
    this.updateSelectedValue()
    this.updateFilterCriteria('')
  }

  public updateFilterCriteria(criteria: string) {
    this.filterCriteria = criteria
    if (criteria.length) {
      this.applyToAllItems((subItem) => {
        subItem.visible = subItem.value.toLowerCase().indexOf(criteria.toLowerCase()) >= 0
      })
    } else {
      this.applyToAllItems((subItem) => {
        subItem.visible = true
      })
    }
  }

  public clearFilterCriteria() {
    if (this.options.search) {
      this.filterControl.setValue('')
      this.updateFilterCriteria('')
    }
  }

  public hasVisibleSubItems(value: SelectGroup) {
    return value.values.filter((v) => v.visible && !v.excluded).length > 0
  }

  public expand(force = false, event?: MouseEvent) {
    if (this.options.disabled) {
      return
    }
    this.expanded = force || !this.expanded
    this.hasFocus = this.expanded

    // Note: Couldn't figure out how to position the options with pure CSS
    if (this.expanded) {
      // Force close other menus
      this.ui.menuOpened$.next(this.selectContainer)

      setTimeout(() => {
        const rect = this.container.nativeElement.getBoundingClientRect()
        const paddingTop = this.options.cssClass !== 'narrow' ? 15 : 0
        const paddingLeft = 15
        const scrollTop = this.container.nativeElement.offsetParent.scrollTop
        this.contentTop = rect.y - this.container.nativeElement.offsetParent.getBoundingClientRect().y + paddingTop + scrollTop + 'px'
        this.contentLeft = rect.x - this.container.nativeElement.offsetParent.getBoundingClientRect().x - paddingLeft + 'px'
        this.contentWidth = rect.width + 30 + 'px'

        if (this.options.search) {
          this.filterInput.nativeElement.focus()
        }
      })
    }

    if (event) {
      event.stopPropagation()
    }

    if (this.afterExpand) {
      this.afterExpand()
    }
  }

  public clickedOutside() {
    this.expanded = false
    this.hasFocus = false
    this.clearFilterCriteria()
    if (this.afterClickedOutside) {
      this.afterClickedOutside()
    }
  }

  public optionsAsGroup() {
    return this.options.items as SelectGroupedItems
  }

  public itemsAsArray() {
    return this.options.items as SelectSubItem[]
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.hasFocus) {
      if (event.code === 'ArrowDown') {
        if (!this.expanded) {
          this.expand()
        }
      }

      if (event.code === 'Enter') {
        // TODO: Not picking this up for some reason
      }

      event.preventDefault()
    }
  }
}
