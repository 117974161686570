import { Component, inject } from '@angular/core'
import { PcStatusOverlayService } from '@shared/services/pc-status-overlay/pc-status-overlay.service'

@Component({
  selector: 'pc-status-overlay',
  templateUrl: './pc-status-overlay.component.html',
  styleUrls: ['./pc-status-overlay.component.scss'],
})
export class PCStatusOverlayComponent {
  public overlayService = inject(PcStatusOverlayService)

  triggerPrimaryAction($event: any) {
    this.overlayService.config?.primaryAction?.execute$.next($event)
  }

  triggerSecondaryAction($event: any) {
    this.overlayService.config?.secondaryAction?.execute$.next($event)
  }

  triggerCloseAction() {
    if (this.overlayService.config?.closeEvent$) {
      this.overlayService.config.closeEvent$.next(null)
    } else {
      this.overlayService.closeStatus()
    }
    if (this.overlayService.config?.onCloseCallback) {
      this.overlayService.config.onCloseCallback()
    }
  }
}
