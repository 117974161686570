import { Component, EventEmitter, Input, Output } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { PcInputBaseComponent } from '../../base-classes/pc-input-base.component'

@Component({
  selector: 'pc-input-textfield',
  templateUrl: './pc-input-textfield.component.html',
  styleUrls: ['./pc-input-textfield.component.scss'],
})
export class PCInputTextfieldComponent extends PcInputBaseComponent {
  @Output() valueChanged: EventEmitter<string> = new EventEmitter()

  @Output() deleted: EventEmitter<boolean> = new EventEmitter()

  @Output() resetted: EventEmitter<boolean> = new EventEmitter()

  // Required
  @Input() control: UntypedFormControl = new UntypedFormControl()

  // Required unless it will look crap
  @Input({ required: true }) placeholder: string

  @Input() size: 'regular' | 'small' = 'regular'

  @Input() variant: 'fit-width'

  @Input() inputID: string

  @Input() initialFocus: boolean

  @Input() disabled: boolean = false

  @Input() errorHint: string | undefined

  @Input() hint: string

  @Input() numbersOnly: boolean = false

  @Input() cross: boolean

  @Input() resetActivated: boolean = false

  @Input()
  standAlone: boolean

  touched: boolean
  reset: boolean = false

  inputFieldChanged(event: Event) {
    const value = (event.target as unknown as HTMLInputElement).value
    this.valueChanged.emit(value)

    this.reset = false
  }

  deleteInput() {
    this.control.setValue('')
    this.valueChanged.emit('')
    this.deleted.emit(true)

    this.reset = true
  }

  public resetAll() {
    this.control.setValue('')
    this.valueChanged.emit('')
    this.resetted.emit(true)

    this.reset = false
  }
}
