import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { KeycloakService } from 'keycloak-angular'
import { Observable, Subject, TimeoutError, forkJoin, from, timeout } from 'rxjs'
import { take } from 'rxjs/operators'
import { PcStatusOverlayService } from '../../shared/services/pc-status-overlay/pc-status-overlay.service'
import { HttpBaseService } from './http-base.service'
import {
  IamCreateUser,
  IamUserResponse,
  Invitation,
  Organisation,
  OrganisationDetailResponse,
  OrganisationDetails,
  OrganisationRole,
  OrganisationsResponse,
  SSOSessionData,
  UserDetailsResponse,
} from './proficloud.interfaces'
import { ProficloudService } from './proficloud.service'

@Injectable({
  providedIn: 'root',
})
export class IamService {
  // Subjects which will be deleted after destroying
  newUserInvited$ = new Subject<void>()

  invitationsLoaded$ = new Subject<Invitation[]>()

  personalInvitationsLoaded$ = new Subject<Invitation[]>()

  organizationLeft$ = new Subject<null>()

  // private rbacPolicies: any[] = []

  constructor(
    private http: HttpClient,
    private proficloud: ProficloudService,
    public router: Router,
    private statusOverlay: PcStatusOverlayService,
    private auth: KeycloakService,
    private httpBase: HttpBaseService
  ) {
    // Once an organization is left, list organizations and switch
    this.organizationLeft$.subscribe({
      next: () => {
        console.debug('Reloading organizations due to leaving. Switching into the first organization of the organization array.')
        // An organization switch is necessary due to the fact, that the old one is gone.
        // If we don't switch, the token will have an old activeOrg value which will lead to inconsistencies
        this.listOrganisations$(false).subscribe((orgas) => {
          this.switchOrganisation(orgas[0])
          this.router.navigate(['/services/device-management/list'])
        })
      },
    })

    // If the flag switchFailed is present, we had an issue while switching an organization, so we need to do a forced organization switch and delete the flag
    if (localStorage.getItem('switchFailed')) {
      this.listOrganisations$(false).subscribe((orgas) => {
        this.switchOrganisation(orgas[0])
        localStorage.removeItem('switchFailed')
        this.router.navigate(['/services/device-management/list'])
      })
    }
  }

  /*
  Once the new IAM will be introduced, this logic needs to change.
   */
  switchOrganisation(newOrganisation: Organisation) {
    this.proficloud.organisationSwitchBegun$.next(true)

    this.updateIamActiveOrganisation(newOrganisation.organizationId).subscribe({
      next: () => {
        this.updateTokenAndCurrentOrg(newOrganisation)
          .pipe(take(1))
          .subscribe({
            next: () => {
              this.proficloud.organisationSwitched$.next(this.proficloud.currentOrganisation)
            },
            error: (err) => {
              console.error(err.msg)
            },
          })
      },
      error: (err) => {
        console.error(err)

        // If this happens the state of the app is broken. No call works anymore. Reload required. No errors
        if (err instanceof TimeoutError) {
          // TODO: We should not be calling statusOverlay methods in the service layer!
          this.statusOverlay.showStatus(this.proficloud.statusOverlays.switchOrganisationStuckError)
          // If this happens write a local storage flag and reload
          setTimeout(() => {
            localStorage.setItem('switchFailed', '1')
            this.router.navigate(['/services/device-management/list'])
            window.location.reload()
          }, 5000)
        } else {
          this.statusOverlay.showStatus(this.proficloud.statusOverlays.switchOrganisationError)
        }
      },
    })
  }

  updateTokenAndCurrentOrg(newOrganisation: Organisation) {
    const instance = this.auth.getKeycloakInstance()
    return new Observable<boolean>((sub) => {
      // we set 90000 seconds which are 25 hours. The tokens provided by KC are valid for 24 hours.
      // we do this to make sure we get a new token with the new active organisation field
      from(instance.updateToken(90000)).subscribe({
        next: (updated) => {
          // token was updated
          if (updated) {
            // Although there is an event listener in the iam service, writing the token to the state should happen here as well,
            // since the oauth lib does not trigger the token_received event sometimes.
            if (instance.token && instance.refreshToken) {
              // console.log(this.proficloud.parseJwt(instance.token))
              localStorage.setItem('access_token', instance.token)
              localStorage.setItem('refresh_token', instance.refreshToken)
            } else {
              console.error('No keycloak instance found. Logging out due to session error.')
              this.proficloud.performLogout()
            }

            // overwrite the session data in proficloud with the new token
            const sessionData: SSOSessionData = {
              access_token: localStorage.getItem('access_token'),
              refresh_token: localStorage.getItem('refresh_token'),
            }

            // Load the auth data from session storage (stored by the oauth lib)
            this.proficloud.decodeSingleSignOnSession(sessionData)
            /*
              Check that the new access token has the same active organisation that was just selected
              It can happen, that something went wrong during the organization switch
              If this happens the activeOrg field inside the token does not fit to the organization it should switch into
              In this case it is necessary to switch into another organization.
            */
            if (this.proficloud.keycloakData.access_token?.activeOrganization === newOrganisation.organizationId) {
              this.setActiveOrganization()
              sub.next(true)
              sub.complete()
            } else {
              sub.error({
                id: 'TOKEN_ACTIVEORG_MISMATCH',
                msg: "Token activeOrg field and desired organization don't fit.",
              })
              sub.complete()
            }
          } else {
            console.error(
              "Token was still valid and therefore wasn't updated. The token will be updated if it expires within the next 25 hours. Has the token validity been increased in the backend?"
            )
            sub.error({
              id: 'TOKEN_REFRESH_FAILED',
              msg: 'Updating token failed.',
            })
            sub.complete()
          }
        },
      })
    })
  }

  updateToken() {
    const instance = this.auth.getKeycloakInstance()
    return new Observable<boolean>((sub) => {
      // we set 90000 seconds which are 25 hours. The tokens provided by KC are valid for 24 hours.
      // we do this to make sure we get a new token with the new active organisation field
      from(instance.updateToken(90000)).subscribe({
        next: (updated) => {
          // token was updated
          if (updated) {
            // Although there is an event listener in the iam service, writing the token to the state should happen here as well,
            // since the oauth lib does not trigger the token_received event sometimes.
            if (instance.token && instance.refreshToken) {
              // console.log(this.proficloud.parseJwt(instance.token))
              localStorage.setItem('access_token', instance.token)
              localStorage.setItem('refresh_token', instance.refreshToken)
            } else {
              console.error('No keycloak instance found. Logging out due to session error.')
              this.proficloud.performLogout()
            }

            // overwrite the session data in proficloud with the new token
            const sessionData: SSOSessionData = {
              access_token: localStorage.getItem('access_token'),
              refresh_token: localStorage.getItem('refresh_token'),
            }

            // Load the auth data from session storage (stored by the oauth lib)
            this.proficloud.decodeSingleSignOnSession(sessionData)

            sub.next(true)
            sub.complete()
          } else {
            sub.error({
              id: 'TOKEN_REFRESH_FAILED',
              msg: 'Updating token failed.',
            })
          }
        },
      })
    })
  }

  /**
   * API Methods
   */

  getIamUser() {
    const url = this.httpBase.backendUrls.iamUrl + `/user`
    return this.http.get<IamUserResponse>(url)
  }

  createIamUser(newUser: IamCreateUser) {
    const url = this.httpBase.backendUrls.iamUrl + '/user'
    newUser.redirectUri = `${window.location.origin}/registration-callback?email=${newUser.email}`
    return this.http.post(url, newUser)
  }

  getIamUserAttributes() {
    const url = this.httpBase.backendUrls.iamUrl + `/user/attributes`
    return this.http.get(url)
  }

  updateIamActiveOrganisation(organisationID: string) {
    const url = this.httpBase.backendUrls.iamUrl + `/user/attributes`
    return this.http.put(url, { attributes: { activeOrganization: [organisationID] } }).pipe(timeout(5000))
  }

  getIamOrganisations() {
    const url = this.httpBase.backendUrls.iamUrl + '/organizations'
    return this.http.get(url)
  }

  getIamOrganisationsDetails(organisationId: string) {
    const url = this.httpBase.backendUrls.iamUrl + `/organizations/${organisationId}`
    return this.http.get(url)
  }

  setUserRole(organisationId: string, userId: string, role: string) {
    const url = this.httpBase.backendUrls.iamUrl + '/organizations/' + organisationId + '/members/' + userId
    return this.http.put(url, { userRole: role })
  }

  setOrganizationName(organisationId: string, name: string) {
    const url = this.httpBase.backendUrls.iamUrl + '/organizations/' + organisationId
    return this.http.put(url, { organizationName: name })
  }

  getIamOrganisationMembers(organisationId: string) {
    const url = this.httpBase.backendUrls.iamUrl + `/organizations/${organisationId}/members`
    return this.http.get(url)
  }

  createIamOrganisation(organizationName: string) {
    const url = this.httpBase.backendUrls.iamUrl + '/organizations'
    const payload = {
      organizationName: organizationName,
    }
    return this.http.post<OrganisationDetailResponse>(url, payload)
  }

  leaveIamOrganisation(organizationId: string, userId: string) {
    const url = this.httpBase.backendUrls.iamUrl + `/organizations/${organizationId}/members/${userId}`
    return this.http.delete(url)
  }

  // DANGEROUS PLEASE DON'T USE!!
  // deleteIamOrganisation(organizationId: string) {
  //   const url = this.httpBase.backendUrls.iamUrl + `/organizations/${organizationId}`
  //   return this.http.delete(url, { headers: new HttpHeaders(this.proficloud.getHeaderDict()) })
  // }

  inviteUser(organisationId: string, userEmail: string, userRole: OrganisationRole, message: string) {
    const url = this.httpBase.backendUrls.iamUrl + `/organizations/${organisationId}/invitations`
    const payload = {
      userEmail,
      userRole,
      message,
    }
    return this.http.post(url, payload)
  }

  acceptInvitation(organizationId: string, invitationId: string) {
    const url = this.httpBase.backendUrls.iamUrl + `/organizations/${organizationId}/invitations/${invitationId}`
    this.http.put(url, { action: 'accept' }).subscribe({
      next: () => {
        this.statusOverlay.showStatus(this.proficloud.statusOverlays.acceptInvitationSuccess)
        this.router.navigate(['/services/device-management/list'])
      },
      error: (err: HttpErrorResponse) => {
        console.log(err)
        this.statusOverlay.showStatus(this.proficloud.statusOverlays.acceptInvitationError)
        setTimeout(() => {
          this.router.navigate(['/services/device-management/list'])
        }, 3000)
      },
    })
  }

  getInvitations(organizationId: string) {
    const url = this.httpBase.backendUrls.iamUrl + `/organizations/${organizationId}/invitations`
    return this.http.get(url)
  }

  getPersonalInvitations() {
    const url = this.httpBase.backendUrls.iamUrl + `/user/invitations`
    return this.http.get(url)
  }

  requestPasswordReset(email: string) {
    const url = this.httpBase.backendUrls.iamUrl + `/user/credentials/reset-request`
    return this.http.post(url, { email })
  }

  requestPasswordResetCR(email: string) {
    const url = this.httpBase.backendUrls.iamUrl + `/user/credentials/reset-request`
    return this.http.post(url, { email: email, passwordResetUrl: 'https://app.charge-repay.io/reset-password', wth: 'CHARGEREPAY' })
  }

  setNewPassword(email: string, newPassword: string, securityToken?: string) {
    const url = this.httpBase.backendUrls.iamUrl + `/user/credentials`

    // TODO: Add case for logged in user which takes old password instead of token
    const params: any = {
      securityToken,
      email,
      newPassword,
    }
    return this.http.put(url, params)
  }

  setNewEmail(securityToken: string) {
    const url = this.httpBase.backendUrls.iamUrl + `/user/change-email`
    return this.http.put(url, { securityToken })
  }

  // There are cases where setting the active organization will disturb the demanded flow.
  // eg: Leaving an organization requires listing the organizations which are left, switching into it and eventually setting the active org.
  listOrganisations$(setActiveOrganization: boolean = true): Observable<Organisation[]> {
    return new Observable<Organisation[]>((sub) => {
      this.getIamOrganisations().subscribe({
        next: (res: OrganisationsResponse) => {
          const detailsCalls: Observable<{ data: OrganisationDetails }>[] = []
          const organizations: Organisation[] = []

          // if the list of organizations is null the backend call was bad and the app is in a faulty state and the user needs to be logged off
          if (!res.data || res.data.length === 0) {
            this.statusOverlay.showStatus(this.proficloud.statusOverlays.listOrganisationStuckError)
            setTimeout(() => {
              this.proficloud.performLogout()
              this.statusOverlay.resetStatus()
              this.router.navigate(['/authenticate'])
            }, 5000)
            return
          }

          for (const org of res.data) {
            org.expansionState = 'collapsed'
            detailsCalls.push(
              this.getIamOrganisationsDetails(org.organizationId) as Observable<{
                data: OrganisationDetails
              }>
            )
          }

          forkJoin(detailsCalls).subscribe({
            next: (details) => {
              details.forEach((d) => {
                const organization = res.data.find((o) => o.organizationId === d.data.organizationId)

                if (organization) {
                  organization.organizationDetails = d.data
                  organizations.push(organization)
                } else {
                  console.warn('Organisation not found')
                }
              })
              // Set organizations including details
              this.proficloud.organisations = organizations
              // set the current organization field once the organizations were listed
              if (setActiveOrganization) {
                this.setActiveOrganization()
              }
              // Inform others that organizations have been set
              this.proficloud.organisationsListed$.next(this.proficloud.organisations)

              sub.next(organizations)
              sub.complete()
            },
          })
        },
        error: (err: HttpErrorResponse) => {
          console.error(err)
          sub.error(err)
          sub.complete()
        },
      })
    })
  }

  // This will set the active organization to the activeOrg field of the token.
  // Warning: don't set it to something else, this will cause asynchronicity
  setActiveOrganization() {
    const activeOrganization = this.proficloud.organisations.filter((o) => o.organizationId === this.proficloud.keycloakData.access_token?.activeOrganization)
    this.proficloud.currentOrganisation = activeOrganization[0]
  }

  loadOrganizationInvitations(organisationId: string) {
    this.getInvitations(organisationId).subscribe({
      next: (res: any) => {
        const invitations: Invitation[] = []
        const resData: any[] = res.data
        if (resData.length > 0) {
          resData.forEach((invitationRes) => {
            const newInvitation: Invitation = invitationRes as Invitation
            if (newInvitation.status === 'PENDING') {
              newInvitation.createdTime = new Date(newInvitation.createdTime).toLocaleDateString()
              invitations.push(newInvitation)
            }
          })
        }
        this.invitationsLoaded$.next(invitations)
      },
      error: (error) => {
        this.invitationsLoaded$.next([])
        console.error(error)
      },
    })
  }

  loadPersonalInvitations() {
    this.getPersonalInvitations().subscribe({
      next: (res: any) => {
        const invitations: Invitation[] = []
        const resData: any[] = res.data
        if (resData.length > 0) {
          resData.forEach((invitationRes) => {
            const newInvitation: Invitation = invitationRes as Invitation
            if (newInvitation.status === 'PENDING') {
              newInvitation.createdTime = new Date(newInvitation.createdTime).toLocaleDateString()
              invitations.push(newInvitation)
            }
          })
        }
        this.personalInvitationsLoaded$.next(invitations)
      },
      error: (error) => {
        this.invitationsLoaded$.next([])
        console.error(error)
      },
    })
  }

  updateUserDetails(fieldsData: { firstName?: string; lastName?: string; email?: string }) {
    const url = this.httpBase.backendUrls.iamUrl + `/user`

    return this.http.put(url, {
      firstName: fieldsData.firstName,
      lastName: fieldsData.lastName,
      ...(fieldsData.email && { email: fieldsData.email }),
    })
  }

  updateUserAttributes(attributes: {}) {
    const url = this.httpBase.backendUrls.iamUrl + `/user/attributes`

    return this.http.put(url, { attributes: attributes })
  }

  deleteOwnUser() {
    const url = `${this.httpBase.backendUrls.iamUrl}/user`
    const httpOptions = {
      body: { KeycloakUserID: this.proficloud.keycloakData.access_token?.sub },
    }
    return this.http.delete(url, httpOptions)
  }

  /**
   *   load all user related data in parallel
   */
  refreshUserDetails() {
    forkJoin([this.getIamUser(), this.getIamUserAttributes()]).subscribe({
      next: ([user, attributes]) => {
        this.proficloud.keycloakData.userDetails = user as UserDetailsResponse // Note: This is a subset so can be cast (but it's not great)
        this.proficloud.keycloakData.userDetails.data.attributes = (attributes as any).data

        // broadcast
        this.proficloud.userDataFetched$.next(user)
      },
      error: (error: HttpErrorResponse) => {
        this.proficloud.logoutOnUnauthorised(error)
      },
    })
  }

  declineInvitation(invitationId: string) {
    const url = this.httpBase.backendUrls.iamUrl + `/user/invitations/${invitationId}`
    return this.http.put(url, { action: 'decline' })
  }

  /**
   * Checks that an organisation ID is inside the current array of organizations
   */
  isInsideOrganisations(organisationId: string): boolean {
    let foundOrganisation = false
    this.proficloud.organisations.forEach((organisation) => {
      if (organisation.organizationId === organisationId) {
        foundOrganisation = true
      }
    })
    return foundOrganisation
  }
}
