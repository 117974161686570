import { COMMA, ENTER } from '@angular/cdk/keycodes'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { ProficloudInputConfig } from '@shared/type-definitions/types'
import { ValidationService } from 'src/app/modules/proficloud/modules/device-management/services/validation.service'
import { PcInputBaseComponent } from '../../base-classes/pc-input-base.component'

@Component({
  selector: 'pc-input-chiplist',
  templateUrl: './pc-input-chiplist.component.html',
  styleUrls: ['./pc-input-chiplist.component.scss'],
})
export class PCInputChiplistComponent extends PcInputBaseComponent implements OnInit {
  constructor(public validation: ValidationService) {
    super()
  }
  @Output() valueChanged: EventEmitter<string[]> = new EventEmitter()

  @Output() deleted: EventEmitter<boolean> = new EventEmitter()

  @Output() resetted: EventEmitter<boolean> = new EventEmitter()

  @Input() size: 'regular' | 'small' = 'regular'

  @Input() variant: 'fit-width'

  @Input() control: UntypedFormControl

  @Input({ required: true }) placeholder: string

  @Input() label: string

  @Input() errorHint: string

  @Input() hint: string

  @Input() search: boolean

  @Input() inputID: string

  @Input() inputValue: string[] = []

  @Input() cross: boolean

  @Input() resetActivated: boolean = false

  @Input() analyticsID: string

  touched: boolean
  chipsField: ProficloudInputConfig
  tagLengthLimit = 42
  separatorKeysCodes: number[] = [ENTER, COMMA]
  lastInputValue: string = ''
  reset: boolean = false

  ngOnInit() {
    if (!this.chipsField?.placeholder) {
      this.chipsField = this.validation.tagsField(this.placeholder, this.tagLengthLimit)
    } else {
      this.chipsField = this.validation.tagsField(this.chipsField.placeholder, this.tagLengthLimit)
    }
    this.chipsField.value = this.inputValue
  }

  public deleteInput() {
    this.chipsField.value = []
    this.control.setValue('')
    this.chipsField.control.setValue('')
    this.valueChanged.emit(this.chipsField.value)
    this.deleted.emit(true)

    this.reset = true
  }

  public addTag(value: string) {
    // addTag is (amongst others) triggered when the user clicks outside of the field
    // this is also the case when the user has not typed a new tag and therefore is not actually adding a new tag
    // this if statement makes sure the "reset" value is not influenced by this behavior
    if (value.length === 0 && this.lastInputValue.length === 0) {
      return
    }

    if (value.length > this.tagLengthLimit) {
      return
    }
    this.chipsField.value = this.chipsField.value as string[]

    if (value.trim().length > 0 && !this.chipsField.value.includes(value.trim())) {
      this.chipsField.value.push(value.trim())
    }

    this.control.setValue(null)
    this.chipsField.control.setValue('')

    this.valueChanged.emit(this.chipsField.value)

    this.reset = false
  }

  public removeTag(tag: string) {
    const index = this.inputValue.indexOf(tag)
    this.inputValue.splice(index, 1)
  }

  // https://proficloud.atlassian.net/browse/DMS-568
  public onChipBlur(event: FocusEvent) {
    const value = (event.target as HTMLInputElement).value
    if (value.length > 0) {
      this.addTag(value)
    }
  }

  public resetAll() {
    this.chipsField.value = []
    this.control.setValue('')
    this.chipsField.control.setValue('')
    this.valueChanged.emit(this.chipsField.value)
    this.resetted.emit(true)

    this.reset = false
  }

  public completeId(suffix: string) {
    return this.analyticsID + suffix
  }
}
