import { Pipe, PipeTransform } from '@angular/core'
import { FirmwareUpdate } from '@dms/interfaces/dms.interfaces'

@Pipe({
  name: 'firmwareFilter',
  pure: false,
})
export class FirmwareFilterPipe implements PipeTransform {
  transform(updatesArray?: FirmwareUpdate[]): FirmwareUpdate | false {
    if (!updatesArray) {
      return false
    }

    const sorted = updatesArray.sort((a, b) => (a.created_at < b.created_at ? 1 : -1))

    const latest = sorted[0]

    if (!latest) {
      return false
    }

    // check how old the latest instead
    if (!latest.active) {
      // return false
    }

    return latest
  }
}

@Pipe({
  name: 'firmwareHistory',
  pure: false,
})
export class FirmwareHistoryPipe implements PipeTransform {
  transform(updatesArray: FirmwareUpdate[] | undefined, ...args: unknown[]): FirmwareUpdate[] {
    if (!updatesArray) {
      return []
    }

    return updatesArray.sort((a, b) => (a.created_at < b.created_at ? 1 : -1))
  }
}
