import { Component } from '@angular/core'
import { FormControl } from '@angular/forms'
import { DateRange } from '@angular/material/datepicker'
import { PcStatusOverlayService } from '@shared/services/pc-status-overlay/pc-status-overlay.service'
import { DropDownItem, PCOverlayConfig, PCStatusOverlayConfig } from '@shared/type-definitions/types'
import { addDays } from 'date-fns'
import { ITab } from '../pc-tab-bar/pc-tab-bar.component'

@Component({
  selector: 'app-kitchen-sink',
  templateUrl: './kitchen-sink.component.html',
  styleUrls: ['./kitchen-sink.component.scss'],
})
export class KitchenSinkComponent {
  sampleTabs: ITab[] = [
    {
      id: 'sections',
      name: 'Sections',
      selected: false,
    },
    {
      id: 'buttons',
      name: 'Buttons',
      selected: false,
    },
    {
      id: 'inputs',
      name: 'Inputs',
      selected: false,
    },
    {
      id: 'selects',
      name: 'Dropdown Selects',
      selected: false,
    },
    {
      id: 'date-pickers',
      name: 'Date Pickers',
      selected: false,
    },
    {
      id: 'overlays',
      name: 'Overlays',
      selected: false,
    },
    {
      id: 'scroll',
      name: 'Scroll',
      selected: true,
    },
  ]

  scrollItems = ['item1']

  showStandardOverlay = false

  standardOverlayConfig: PCOverlayConfig = {
    title: 'This is a standard overlay',
  }

  testControl = new FormControl()
  dateRangeControl = new FormControl()
  dateControl = new FormControl()

  dropdownSelectOptions: DropDownItem<string>[] = [
    {
      id: 'first',
      label: 'First',
    },
    {
      id: 'second',
      label: 'Second',
    },
    {
      id: 'third',
      label: 'Third',
    },
  ]

  dropdownSelectControl = new FormControl<string>('first')

  dateRangeSelected(newRange: DateRange<Date>) {
    console.log('new range', newRange)
  }

  public exampleLoadingBusy: PCStatusOverlayConfig = {
    type: 'spinner',
    message: 'Loading',
    closeable: true,
  }
  public exampleLoadingSuccess: PCStatusOverlayConfig = {
    type: 'success',
    message: 'Loaded successfully.',
    closeable: true,
  }
  public exampleLoadingError: PCStatusOverlayConfig = {
    type: 'error',
    message: 'An error occured while loading.',
    closeable: true,
  }

  public addMoreItems() {
    for (let i = 0; i < 20; i++) {
      this.scrollItems.push(`item${i}`)
    }
  }

  public onScrollDown() {
    console.log('asdf')
  }

  constructor(public statusOverlay: PcStatusOverlayService) {
    this.dateControl.setValue(new Date())
    this.dateRangeControl.setValue(new DateRange<Date>(new Date(), addDays(new Date(), -1)))

    this.addMoreItems()
    this.addMoreItems()
    this.addMoreItems()
  }
}
