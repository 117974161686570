import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { environment } from '@environments/environment'
import { IamService } from '@services/iam.service'
import { IamCreateUser } from '@services/proficloud.interfaces'
import { ProficloudService } from '@services/proficloud.service'
import { UiService } from '@services/ui/ui.service'
import { ProficloudInputConfig } from '@shared/type-definitions/types'
import { AppService } from 'src/app/app.service'
import { PcStatusOverlayService } from '../../services/pc-status-overlay/pc-status-overlay.service'
import {
  numberValidator,
  passwordConfirmationValidator,
  smallCharacterValidator,
  specialCharacterValidator,
  upperCharacterValidator,
} from '../../validators/custom-validators'

@Component({
  selector: 'app-iam-register',
  templateUrl: './iam-register.component.html',
  styleUrls: ['./iam-register.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class IamRegisterComponent implements OnInit {
  invitationToken?: string

  environment = environment

  registerFields: ProficloudInputConfig[] = [
    {
      type: 'select',
      selectValues: this.ui.getSelectOptionsForCountries(),
      placeholder: 'Country',
      value: '',
      control: new UntypedFormControl('', [Validators.required]),
      key: 'country',
      inputId: 'country',
      changed: () => {
        // TODO
      },
    },
    {
      type: 'text',
      placeholder: 'First name',
      value: '',
      control: new UntypedFormControl('', [Validators.required]),
      key: 'firstName',
      inputId: 'firstName',
      maxLength: 27,
    },
    {
      type: 'text',
      placeholder: 'Last name',
      value: '',
      control: new UntypedFormControl('', [Validators.required]),
      key: 'lastName',
      inputId: 'lastName',
      maxLength: 27,
    },
    {
      type: 'password',
      placeholder: 'Password',
      value: '',
      control: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(72),
        smallCharacterValidator(),
        upperCharacterValidator(),
        numberValidator(),
        specialCharacterValidator(),
      ]),
      key: 'password',
      inputId: 'password',
    },
    {
      type: 'password',
      placeholder: 'Confirm password',
      value: '',
      control: new UntypedFormControl('', [Validators.required]),
      key: 'confirmPassword',
      inputId: 'confirmPassword',
    },
  ]

  registerForm: UntypedFormGroup

  constructor(
    public app: AppService,
    public proficloud: ProficloudService,
    private iam: IamService,
    public router: Router,
    private statusOverlay: PcStatusOverlayService,
    public ui: UiService
  ) {}

  ngOnInit(): void {
    const token = this.app.getQueryParam('invitationToken')
    const email = this.app.getQueryParam('email')

    // If this is an invitation link then set the invitation token and email address
    if (!!token && email) {
      this.invitationToken = this.app.getQueryParam('invitationToken') || undefined
      this.registerFields.unshift({
        type: 'text',
        placeholder: 'Email',
        value: email,
        control: new UntypedFormControl(email, [Validators.required, Validators.email]),
        key: 'email',
        inputId: 'email',
        disabled: true,
      })
    } else {
      this.registerFields.unshift({
        type: 'text',
        placeholder: 'Email',
        value: '',
        control: new UntypedFormControl('', [Validators.required, Validators.email]),
        key: 'email',
        inputId: 'email',
      })

      this.registerFields.unshift({
        type: 'text',
        placeholder: 'Organization name',
        value: '',
        control: new UntypedFormControl('', [Validators.required]),
        key: 'organisationName',
        inputId: 'organisationName',
        maxLength: 27,
      })
    }

    const controlsObject = this.ui.formFieldsToObject(this.registerFields)
    controlsObject.termsAndConditions = new UntypedFormControl(false, [Validators.requiredTrue])
    this.registerForm = new UntypedFormGroup(controlsObject)

    // set validator for the password confirmation check
    this.registerForm
      .get('confirmPassword')
      ?.setValidators([Validators.required, passwordConfirmationValidator(this.registerForm, 'password', 'confirmPassword')])
  }

  public register() {
    this.statusOverlay.showStatus(this.proficloud.statusOverlays.registerBusy)
    const newUser: IamCreateUser = {
      // convert email to lower case since IAM is not doing it, which can lead to issues lat on.
      email: (this.registerForm.controls['email'].value as string).toLowerCase(),
      firstName: this.registerForm.controls['firstName'].value,
      lastName: this.registerForm.controls['lastName'].value,
      password: this.registerForm.controls['password'].value,
      country: this.registerForm.controls['country'].value,
      // This is ok as we checked beforehand
      termsAndConditions: 'Accepted',
      // Set invitation token if there is one
      invitationToken: this.invitationToken ? this.invitationToken : undefined,
      // Only set if the invation token is not given
      organizationName: this.invitationToken ? '' : this.registerForm.controls['organisationName'].value,
      redirectUri: '',
      locale: 'en-GB', // Current Default
    }

    this.iam.createIamUser(newUser).subscribe({
      next: () => {
        this.statusOverlay.showStatus(this.proficloud.statusOverlays.registerSuccess)
        setTimeout(() => {
          this.statusOverlay.resetStatus()
          this.router.navigate(['/'])
        }, 2000)
      },
      error: (err: HttpErrorResponse) => {
        if (err.error?.error?.id === 'iam.USER_EXISTS_ERROR') {
          this.statusOverlay.showStatus(this.proficloud.statusOverlays.registerUserExistsError)
          console.log(err)
        } else {
          this.statusOverlay.showStatus(this.proficloud.statusOverlays.registerError)
          console.log(err)
        }
      },
    })
  }
}
