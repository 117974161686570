import { HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Device, DeviceInfo, FirmwareUpdate } from '@dms/interfaces/dms.interfaces'
import { HttpBaseService } from '@services/http-base.service'
import { ProficloudService } from '@services/proficloud.service'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { map, Subject } from 'rxjs'
import { ApplicationImage, ApplicationImageResponse, ApplicationUpdate, SSEUpdateManagementMessage, UpdateStage, UpdateStatus } from './application.interfaces'
import { ApplicationService } from './application.service'
import { FirmwareStatusCodes, FirmwareUpdateIcon, FirmwareUpdateStageLabel } from './firmware.interfaces'

@Injectable({
  providedIn: 'root',
})
export class FirmwareService {
  fwList: any
  currentFwUpdate: ApplicationUpdate
  isActiveUpdate$: Subject<boolean> = new Subject()
  fwUpdateStarted$: Subject<any> = new Subject()
  fwUpdateCompleted$: Subject<{ success: boolean; message: string }> = new Subject()
  currentFWUpdateId: string
  deviceId: any
  currentUpdateStages: UpdateStage[]
  update: ApplicationUpdate
  constructor(
    public proficloud: ProficloudService,
    private httpBase: HttpBaseService,
    private application: ApplicationService
  ) {}

  currentUpdateId: string
  currentApplication: ApplicationImage
  currentUpdate: ApplicationUpdate
  appItems: any
  timestampStage: string
  private messageEventSource: EventSourcePolyfill
  firmwareUpdate$ = new Subject<DeviceInfo | false>()
  cancelFirmwareUpdate$ = new Subject<FirmwareUpdate | false>()
  firmwareUpdateStarted$: Subject<boolean> = new Subject()

  applicationUpdateStarted$: Subject<boolean> = new Subject()

  applicationUpdateCompleted$: Subject<{ success: boolean; message: string }> = new Subject()
  applicationUpdateDialog$: Subject<Device | false> = new Subject()
  applicationUploaded$: Subject<boolean> = new Subject()

  firmwareUpdateIcons: Record<FirmwareStatusCodes, FirmwareUpdateIcon> = {
    // not started
    '-1': false,
    // compatibility
    0: 'spinner',
    1: 'check',
    2: 'error',
    // download
    3: 'spinner',
    4: 'check',
    5: 'error',
    // download check
    6: 'spinner',
    7: 'check',
    8: 'error',
    // installation
    9: 'spinner',
    10: 'check',
    11: 'error',
    // misc errors
    12: 'error',
    13: 'error',
  }

  firmwareUpdateStages: FirmwareUpdateStageLabel[] = [
    {
      name: 'Step 01 - Checking Permissions',
      key: 'compatibility',
    },
    {
      name: 'Step 02 - Download Image',
      key: 'download',
    },
    {
      name: 'Step 03 - Checking downloaded Image',
      key: 'check_download',
    },
    {
      name: 'Step 04 - Installation',
      key: 'check_install',
    },
  ]

  public openStatusSSE(updateType: string) {
    this.update = updateType === 'Application' ? this.currentUpdate : this.currentFwUpdate
    const url = `${this.httpBase.backendUrls.updateManagementUrl}/api/v3/sse/`
    const token = localStorage.getItem('access_token')
    try {
      this.messageEventSource = new EventSourcePolyfill(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      this.messageEventSource.addEventListener('update', (evt: MessageEvent) => {
        const event: SSEUpdateManagementMessage = JSON.parse(evt.data)
        this.timestampStage = event.received_at

        let statusToUpdate: UpdateStatus | undefined
        this.loadUpdateStages(this.update)
        if (event.status.includes('abort')) {
          return
        }

        // Stage
        if (this.update) {
          if (event.status.includes('compat')) {
            statusToUpdate = this.update.compatibilityStatus
            this.updateStepTimestamp('Step 1 - Checking Compatability:', event, statusToUpdate)
          } else if (event.status.includes('download')) {
            statusToUpdate = this.update.downloadStatus
            this.updateStepTimestamp('Step 2 - Downloading Image:', event, statusToUpdate)
          } else if (event.status.includes('verify')) {
            statusToUpdate = this.update.verifyStatus
            this.updateStepTimestamp('Step 3 - Verifying Downloaded Image:', event, statusToUpdate)
          } else if (event.status.includes('install')) {
            statusToUpdate = this.update.installStatus
            this.updateStepTimestamp('Step 4 - Installing:', event, statusToUpdate)

            if (event.status.includes('ok')) {
              if (updateType === 'Application') {
                this.applicationUpdateCompleted$.next({ success: true, message: ' Application Update successful.' })
              } else {
                this.fwUpdateCompleted$.next({ success: true, message: 'Firmware Update successful.' })
              }
              this.currentUpdateStages = []
            }
          }
        }
      })
    } catch (e) {
      console.error(e)
    }
  }

  updateStepTimestamp(stepName: string, event: SSEUpdateManagementMessage, statusToUpdate?: UpdateStatus) {
    const stepUpdate = this.currentUpdateStages.find((step) => step.headline === stepName)

    if (stepUpdate) {
      stepUpdate.timestamp = event.received_at

      // Status
      if (!statusToUpdate) {
        console.warn('stage not recognised')
        return
      }
      statusToUpdate.timestamp = stepUpdate.timestamp
      if (event.status.includes('requested')) {
        statusToUpdate.statusCode = 0
        statusToUpdate.message = event.message || 'Requested'
      }

      if (event.status.includes('pending')) {
        statusToUpdate.statusCode = 1
        statusToUpdate.message = event.message || 'Pending'
      }

      if (event.status.includes('ok')) {
        statusToUpdate.statusCode = 2
        statusToUpdate.message = event.message || 'Success'
      }

      if (event.status.includes('fail')) {
        statusToUpdate.statusCode = 3
        statusToUpdate.message = event.message || 'Failed'
      }

      // Set progress if we have one
      if (event.progress) {
        statusToUpdate.progress = event.progress
      }
    }
  }

  //remove below method once moved to update-management
  installApplication(id: string, device: Device) {
    const url = `${this.httpBase.backendUrls.updateManagementUrl}/api/v3/devices/${device.metadata.uuid}/application`
    const payload = {
      context: 'hello!',
      data: {
        id,
      },
    }

    this.proficloud.http.put<null>(url, payload, { observe: 'response' }).subscribe({
      next: (applicationResponse) => {
        // Note: This is a break from the usual pattern - we are getting the next URL from the headers of the response.
        // This is because the backend follows the HATEOS principle.
        // There was a very long conversation about this with the backend guys but basically the conclusion is that it's staying like that.
        // In the words of Peter O'hanrahanrahan: "I don't like it, but I'll have to go along with it!"
        const location = applicationResponse.headers.get('location')
        if (!location) {
          console.warn('Location not found in response headers')
          return
        }
        const updateId = location.split('/')[location.split('/').length - 1]
        this.currentUpdateId = updateId
        this.applicationUpdateStarted$.next(true)

        this.proficloud.http.get(location).subscribe((res: ApplicationUpdate) => {
          this.currentUpdate = res
          this.update = this.currentUpdate
          this.deviceId = res.data.device.id
          this.initApplicationUpdateStatus(this.currentUpdate)
          this.loadUpdateStages(this.currentUpdate)
        })
      },
      error: (error) => {
        this.applicationUpdateStarted$.next(false)
      },
    })
  }

  loadUpdateStages(update: ApplicationUpdate) {
    if (!this.currentUpdateStages || this.currentUpdateStages.length === 0) {
      this.currentUpdateStages = [
        {
          headline: 'Step 1 - Checking Compatability:',
          status: update.compatibilityStatus,
          timestamp: '',
        },
        {
          headline: 'Step 2 - Downloading Image:',
          status: update.downloadStatus,
          timestamp: '',
        },
        {
          headline: 'Step 3 - Verifying Downloaded Image:',
          status: update.verifyStatus,
          timestamp: '',
        },
        {
          headline: 'Step 4 - Installing:',
          status: update.installStatus,
          timestamp: '',
        },
      ]
    }
  }

  initApplicationUpdateStatus(update: ApplicationUpdate) {
    update.compatibilityStatus = {
      statusCode: -1,
      message: 'Not Started',
      progress: 0,
    }
    update.downloadStatus = {
      statusCode: -1,
      message: 'Not Started',
      progress: 0,
    }
    update.verifyStatus = {
      statusCode: -1,
      message: 'Not Started',
      progress: 0,
    }
    update.installStatus = {
      statusCode: -1,
      message: 'Not Started',
      progress: 0,
    }
  }

  public abortCurrentUpdate(updateId: string, updateType: string) {
    const url = `${this.httpBase.backendUrls.updateManagementUrl}/api/v3/updates/${updateId}/abort`
    let headers: HttpHeaders = new HttpHeaders(this.proficloud.getAuthHeader())
    this.proficloud.http
      .put<ApplicationImageResponse>(url, {
        headers,
      })
      .subscribe((res) => {
        if (updateType === 'Application') {
          this.applicationUpdateCompleted$.next({ success: false, message: 'Aborted by user' })
        } else {
          this.fwUpdateCompleted$.next({ success: false, message: 'Aborted by user' })
        }
      })
  }

  getAllFirmwareUpdateHistory() {
    const url = `${this.httpBase.backendUrls.updateManagementUrl}/api/v3/updates?updateType=firmware`
    return this.proficloud.http.get(url)
  }

  loadFirmwareUpdateHistory(device: DeviceInfo) {
    this.getAllFirmwareUpdateHistory().subscribe({
      next: (updates: any) => {
        //return only last 10 updates for now
        device.firmwareUpdates = updates.data.items.slice(-10).reverse()
      },
      error: (error) => {
        this.proficloud.logoutOnUnauthorised(error)
      },
    })
  }

  loadAvailableDeviceFirmware(device: DeviceInfo) {
    const url = `${this.httpBase.backendUrls.updateManagementUrl}/api/v3/devices/${device.metadata.uuid}/firmware`
    this.proficloud.http.get<any>(url).subscribe({
      next: (response) => {
        device.availableFirmware = response.data.upgrade_paths
        if (device.availableFirmware && device.availableFirmware.length > 0) {
          device.firmwareUpdateAllowed = true
        } else {
          device.newestFirmware = undefined
          device.firmwareUpdateAllowed = false
        }
      },
      error: (error) => {
        console.warn('single device firmware error', error)
      },
    })
  }

  listAvailableFirmware(device: DeviceInfo) {
    const url = `${this.httpBase.backendUrls.updateManagementUrl}/api/v3/devices/${device.metadata.uuid}/firmware`
    return this.proficloud.http.get<any>(url).subscribe({
      next: (res) => {
        this.fwList = res.data.upgrade_paths
      },
    })
  }

  startFWUpdate(id: string, device: Device) {
    const url = `${this.httpBase.backendUrls.updateManagementUrl}/api/v3/devices/${device.metadata.uuid}/firmware` //${this.httpBase.backendUrls.updateManagementUrl}/api/v3/updates/${updateId}
    const payload = {
      context: 'hello!',
      data: {
        id,
      },
    }

    this.proficloud.http.post<null>(url, payload, { observe: 'response' }).subscribe({
      next: (fwResponse) => {
        const location = fwResponse.headers.get('location')
        if (!location) {
          console.warn('Location not found in response headers')
          return
        }
        const updateId = location.split('/')[location.split('/').length - 1]
        this.currentFWUpdateId = updateId
        this.firmwareUpdateStarted$.next(true)

        this.proficloud.http.get(location).subscribe((res: any) => {
          this.currentFwUpdate = res
          this.update = this.currentFwUpdate
          this.deviceId = res.data.device.id
          this.initApplicationUpdateStatus(this.currentFwUpdate)
          this.loadUpdateStages(this.currentFwUpdate)
        })
      },
      error: (error) => {
        this.firmwareUpdateStarted$.next(false)
      },
    })
  }

  getFWUpdateName(UpdateId: string) {
    const url = `${this.httpBase.backendUrls.updateManagementUrl}/api/v3/firmwares/${UpdateId}`
    return this.proficloud.http.get<any>(url).pipe(
      map((response) => {
        return response.data.version
      })
    )
  }

  getActiveFWUpdates() {
    const url = `${this.httpBase.backendUrls.updateManagementUrl}/api/v3/updates?active=true&updateType=firmware`
    return this.proficloud.http.get<any>(url)
  }

  populateFWList(device: DeviceInfo) {
    const url = `${this.httpBase.backendUrls.updateManagementUrl}/api/v3/devices/${device.metadata.uuid}/firmware`
    return this.proficloud.http.get<any>(url)
  }

  uploadApplicationFile(file: File) {
    const url = `${this.httpBase.backendUrls.updateManagementUrl}/api/v3/applications`
    const formData = new FormData()
    formData.append('file', new Blob([file]), file.name)
    formData.append('fileName', file.name)

    let headers: HttpHeaders = new HttpHeaders(this.proficloud.getAuthHeader())

    this.proficloud.http
      .post<ApplicationImageResponse>(url, formData, {
        headers,
      })
      .subscribe({
        next: (r) => {
          this.currentApplication = r.data
          this.applicationUploaded$.next(true)
        },
        error: (error) => {
          this.applicationUploaded$.next(false)
        },
      })
  }
}

export function getFirmwareUpdateAllowState(device: DeviceInfo) {
  if (device.firmwareUpdateAllowed === false) {
    return {
      allowed: false,
      reason: 'No newer firmware versions are available for this device',
    }
  }
  // not if the device is offline
  if (!device.metadata.con_connected) {
    return { allowed: false, reason: 'Firmware update is not possible while device is offline' }
  }
  // not if there are no (new firmwares available)
  if (!device.availableFirmware || device.availableFirmware.length === 0) {
    return { allowed: false, reason: 'No suitable firmware found' }
  }
  // if none of the above conditions match, firmware update is allowed
  return { allowed: true, reason: 'device is online, firmware available, no process running' }
}
