<div
  cdkScrollable
  [style.max-height]="forceHeight + 'px'"
  #container
  [class.scroll-vertical]="vertical"
  [class.scroll-horizontal]="horizontal"
  [class.horizontal-hidden]="!horizontal"
  (scroll)="scrolled$.next($event)">
  <ng-content></ng-content>
</div>
