{
  "isoLangs": {
    "en-GB": {
      "name": "English",
      "nativeName": "English"
    },
    "de-DE": {
      "name": "German",
      "nativeName": "Deutsch"
    },
    "it": {
      "name": "Italian",
      "nativeName": "Italiano"
    },
    "es": {
      "name": "Spanish",
      "nativeName": "Español"
    },
    "fr": {
      "name": "French",
      "nativeName": "Français"
    }
  }
}
