import { AfterViewInit, Component, ElementRef, EventEmitter, Injectable, Input, Output, ViewChild } from '@angular/core'
import { FormControl, UntypedFormGroup } from '@angular/forms'
import { NativeDateAdapter } from '@angular/material/core'
import { DateRange, DefaultMatCalendarRangeStrategy, MAT_DATE_RANGE_SELECTION_STRATEGY, MatRangeDateSelectionModel } from '@angular/material/datepicker'
import { DateRangeToken } from '@services/proficloud.interfaces'
import { ProficloudService } from '@services/proficloud.service'
import { UiService } from '@services/ui/ui.service'
import { ChosenTime } from '@shared/type-definitions/types'
import { addDays } from 'date-fns'
import { PcInputBaseComponent } from '../../base-classes/pc-input-base.component'

@Injectable()
export class MondayDateAdapter extends NativeDateAdapter {
  override getFirstDayOfWeek(): number {
    return 1
  }
}

@Component({
  selector: 'pc-input-date-range',
  templateUrl: './pc-input-date-range.component.html',
  styleUrls: ['./pc-input-date-range.component.scss', '../../../shared/styles/shared-styles.scss'],
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy,
    },
    DefaultMatCalendarRangeStrategy,
    MatRangeDateSelectionModel,
  ],
})
export class PCInputDateRangeComponent extends PcInputBaseComponent implements AfterViewInit {
  actualDateRange: DateRange<Date>
  dateRangeToken?: DateRangeToken
  selectedDate: Date

  @Input()
  override form: UntypedFormGroup

  @Input()
  control: FormControl<DateRange<Date> | DateRangeToken | Date>

  @Output()
  rangeSelected = new EventEmitter<DateRange<Date> | DateRangeToken | Date>()

  @Input()
  index: number

  @Input()
  elementID: string

  @Input()
  futureDateSelectionAllow: boolean = false

  @Input()
  single: boolean = false

  @Input()
  time: boolean = true

  @Input()
  size: 'minimal' | 'regular' = 'regular'

  @Input()
  fixed: boolean

  @Input()
  disabled: boolean = false

  @ViewChild('inputValue')
  inputElement: ElementRef

  @ViewChild('pickerContainer')
  pickerContainer: ElementRef

  @ViewChild('selectionContainer')
  selectionContainer: ElementRef
  allowedFutureDate = new Date()

  isTooFarRight = false
  leftOffset?: string
  topOffset?: string
  bottomFixed: boolean = false

  contentLeft = '0px'
  contentTop = '0px'
  contentWidth = '200px'

  expanded: boolean = false
  calendarExpanded: boolean = false
  selectedStartTime: ChosenTime
  selectedEndTime: ChosenTime
  filteredPresets: { key: DateRangeToken; value: string }[] = []

  constructor(
    private ui: UiService,
    public proficloud: ProficloudService,
    private readonly selectionModel: MatRangeDateSelectionModel<Date>,
    private readonly selectionStrategy: DefaultMatCalendarRangeStrategy<Date>
  ) {
    super()

    this.ui.popupPositionRecalculationRequired$.subscribe(() => {
      this.setMenuPosition()
    })
  }

  ngAfterViewInit(): void {
    this.setSelected()

    this.control.valueChanges.subscribe((value) => {
      this.setSelected()
    })

    this.filterPresets()

    this.setMenuPosition()

    if (this.futureDateSelectionAllow) {
      this.allowedFutureDate = new Date(2099, 1, 1)
    }
  }

  public filterPresets(filter?: string) {
    this.filteredPresets = Object.keys(this.proficloud.presetDateRanges).map((key) => {
      return { key: key, value: this.proficloud.presetDateRanges[key] }
    })
    if (filter) {
      this.filteredPresets = this.filteredPresets.filter((preset) => preset.value.toLowerCase().includes(filter.toLowerCase()))
    }
  }

  private setSelected() {
    if (!this.single) {
      if (typeof this.control?.value === 'string' && this.control?.value?.length) {
        this.actualDateRange = this.proficloud.dateRangeFromToken(this.control.value) || new DateRange<Date>(new Date(), addDays(new Date(), -1))
      } else {
        this.actualDateRange = (this.control?.value as DateRange<Date>) || new DateRange<Date>(new Date(), addDays(new Date(), -1))
      }

      this.selectedStartTime = {
        hours: this.actualDateRange.start?.getHours() || 0,
        minutes: this.actualDateRange.start?.getMinutes() || 0,
      }
      // Will be empty if it's first click of the calendar
      if (this.actualDateRange.end) {
        this.selectedEndTime = {
          hours: this.actualDateRange.end.getHours(),
          minutes: this.actualDateRange.end.getMinutes(),
        }
      }
    } else {
      // Single date/time has only a start time and no end time
      this.selectedDate = this.control.value as Date
      this.actualDateRange = new DateRange<Date>(this.selectedDate, null)
      this.selectedStartTime = {
        hours: this.selectedDate.getHours(),
        minutes: this.selectedDate.getMinutes(),
      }
    }
  }

  dateChanged(selectedDate: Date) {
    this.control.setValue(selectedDate)
    this.expanded = false
    this.rangeSelected.emit(selectedDate)
  }

  // Handler for selecting a date from the range picker
  public rangeChanged(selectedDate: Date) {
    delete this.dateRangeToken
    const selection = this.selectionModel.selection
    const newSelection = this.selectionStrategy.selectionFinished(selectedDate, selection)

    // Set the hours and minutes that have been chosen in the time pickers
    if (newSelection.end) {
      let hours = this.selectedEndTime.hours
      newSelection.end.setHours(hours)
      newSelection.end.setMinutes(this.selectedEndTime.minutes)
    }
    if (newSelection.start) {
      newSelection.start.setHours(this.selectedStartTime.hours)
      newSelection.start.setMinutes(this.selectedStartTime.minutes)
    }

    // Update the selection model
    this.selectionModel.updateSelection(newSelection, this)
    this.actualDateRange = new DateRange<Date>(newSelection.start, newSelection.end)
    this.control.setValue(this.actualDateRange)

    if (this.selectionModel.isComplete()) {
      // TODO: Something?
    }
  }

  public handleStartTimeChange(time: ChosenTime) {
    this.selectedStartTime = time
    let hours = time.hours

    this.actualDateRange.start?.setHours(hours)
    this.actualDateRange.start?.setMinutes(time.minutes)

    if (this.single && this.actualDateRange.start) {
      this.control.setValue(this.actualDateRange.start)
    } else {
      this.control.setValue(this.actualDateRange)
    }
  }

  public handleEndTimeChange(time: ChosenTime) {
    this.selectedEndTime = time
    let hours = time.hours
    this.actualDateRange.end?.setHours(hours)
    this.actualDateRange.end?.setMinutes(time.minutes)

    if (this.single && this.actualDateRange.end) {
      this.control.setValue(this.actualDateRange.end)
    } else {
      this.control.setValue(this.actualDateRange)
    }
  }

  public applySelection() {
    // Emit the token if we have one, otherwise the date range
    if (this.single) {
      this.rangeSelected.emit(this.actualDateRange.start as Date)
    } else {
      this.rangeSelected.emit(this.dateRangeToken || this.actualDateRange)
    }
    this.calendarExpanded = false
    this.expanded = false
  }

  public selectByToken(token: DateRangeToken) {
    // Always keep track of the derived date range
    this.dateRangeToken = token
    this.actualDateRange = this.proficloud.dateRangeFromToken(token) || new DateRange<Date>(new Date(), addDays(new Date(), -1))
    this.filterPresets()

    // Note: The value of the control will be the token, not the derived date range
    this.control.setValue(token)
    this.applySelection()
  }

  public expand() {
    if (this.disabled) {
      return
    }
    this.setMenuPosition()
    this.expanded = !this.expanded
    // Force close other menus
    this.ui.menuOpened$.next(this.pickerContainer)
  }

  public setMenuPosition() {
    // Note: This method is clearly an unreadable, brittle pile of shit
    const getFirstAbsoluteParent = (element: ElementRef) => {
      let parent = element.nativeElement.parentElement
      while (parent) {
        if (window.getComputedStyle(parent, null).position === 'absolute') {
          return parent
        }
        if (!parent.parentElement) {
          return parent
        }
        parent = parent.parentElement
      }
    }
    const pickerWidth = this.calendarExpanded ? 556 : 248 // Expanded
    const frameElement = getFirstAbsoluteParent(this.pickerContainer)
    const frameOffset = frameElement.getBoundingClientRect().x
    const pickerOffset = this.pickerContainer.nativeElement.getBoundingClientRect().x

    // Set left/right offset
    if (this.pickerContainer.nativeElement.getBoundingClientRect().x > window.innerWidth - pickerWidth) {
      this.isTooFarRight = true
      delete this.leftOffset
    } else {
      if (this.fixed) {
        this.leftOffset = pickerOffset + 'px' // - frameOffset
      } else {
        this.isTooFarRight = false
      }
    }

    // Set top/bottom offset
    const pickerBounds = this.pickerContainer.nativeElement.getBoundingClientRect()
    const selectionHeight = 356

    if (this.fixed) {
      if (pickerBounds.top + 34 > window.innerHeight - selectionHeight) {
        this.bottomFixed = true
        delete this.topOffset
      } else {
        this.bottomFixed = false
        this.topOffset = pickerBounds.y + 34 + 'px'
      }
    }
  }
}
