import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import {
  CreateDevicePostData,
  Device,
  DeviceInfo,
  DeviceListResponse,
  DeviceMetaData,
  LastTrafficlightsResponse,
  LogsResponse,
  MetadataResponse,
} from '@dms/interfaces/dms.interfaces'
import { HttpBaseService } from '@services/http-base.service'
import { ApiResponseV_1_2 } from '@services/proficloud.interfaces'
import { ProficloudService } from '@services/proficloud.service'
import { AppService } from 'src/app/app.service'
import { PairedIds } from '../services/bulk-deletion.interfaces'
import { BulkEditData } from '../services/bulk-edit.interfaces'
import { DeviceGroup, DeviceGroupDevice } from '../services/device-group.interfaces'
import { DeviceTypeInformationResponse, ServiceInformationResponse } from '../services/device.service'

@Injectable({
  providedIn: 'root',
})
export class DeviceManagementHttpService extends HttpBaseService {
  constructor(
    protected override app: AppService,
    private httpClient: HttpClient,
    public proficloud: ProficloudService
  ) {
    super(app)
  }

  public getOrganisationDevices(organisationId: string) {
    const url = `${this.backendUrls.deviceLifecycleUrl}/endpoints?limit=0&include=metadata&metadataFilter=${encodeURIComponent(
      '{"organizationId":"' + organisationId + '"}'
    )}`

    return this.httpClient.get<DeviceListResponse>(url)
  }

  public fetchDeviceLogs(fromDate: Date, toDate: Date, device: DeviceInfo) {
    const metricName = 'con_logs'
    const fromDate_str = fromDate.toISOString()
    const toDate_str = toDate.toISOString()

    if (!device.customLogs || !device.customLogs.range) {
      console.warn('Cust logs not present')
      return
    }
    device.customLogs.range.last_end = new Date(device.customLogs.range.end)
    device.customLogs.range.last_start = new Date(device.customLogs.range.start)

    // eslint-disable-next-line max-len
    const url = `${this.backendUrls.apiTsdBaseUrl}/applications/proficloud/time-series/data?endpointId=${device.endpointId}&timeSeriesName=${metricName}&fromDate=${fromDate_str}&toDate=${toDate_str}`

    return this.httpClient.get<LogsResponse>(url)
  }

  public addDeviceLogEntry(device: DeviceInfo, entry: { message: string } = { message: 'how u doing?' }) {
    const endpointId = device.endpointId
    const payload = {
      [endpointId]: {
        con_logs: [
          {
            timestamp: new Date().toISOString(),
            values: {
              con_LogLevel: 1,
              con_LogMessage: entry.message,
              con_LogTags: 'User.submitted.entry',
            },
          },
        ],
      },
    }
    const url = `${this.backendUrls.apiTsdBaseUrl}/applications/proficloud/time-series/data`

    return this.httpClient.put(url, payload)
  }

  public createDevice(createData: CreateDevicePostData, virtual: boolean) {
    let deviceUrl = `${this.backendUrls.middlewareUrl}/device`

    if (virtual) {
      deviceUrl = `${this.backendUrls.middlewareUrl}/device/virtual-device`
    }

    return this.httpClient.post<HttpResponse<{ data: any }>>(
      deviceUrl,
      {
        organizationId: createData.organizationId,
        appVersion: {
          name: 'proficloud-v1',
        },
        metadata: {
          deviceName: createData.deviceName,
          comment: createData.comment,
          uuid: createData.uuid,
          lat: createData.lat,
          lng: createData.lng,
          address: createData.address,
          tags: createData.tags,
        },
      },
      { observe: 'response' }
    )
  }

  public deleteDevice(device: Device) {
    const url = `${this.backendUrls.middlewareUrl}/device`

    const body = {
      endpointId: device.endpointId,
      uuid: device.metadata.uuid,
    }

    return this.httpClient.delete(url, { body: body })
  }

  deleteDevices(bulkDeletionData: PairedIds[]) {
    const url = `${this.backendUrls.multiDeviceAdapterUrl}/delete_devices`
    return this.httpClient.delete(url, { body: bulkDeletionData })
  }

  editDevices(devicesEditData: BulkEditData) {
    const url = `${this.backendUrls.multiDeviceAdapterUrl}/edit_devices`
    return this.httpClient.put(url, devicesEditData)
  }

  public updateDeviceDetail(endpointId: string, deviceFields: DeviceMetaData) {
    const url = `${this.backendUrls.middlewareUrl}/device`
    const payload = {
      endpointId,
      fields: deviceFields,
    }
    return this.httpClient.put<null>(url, payload)
  }

  public getAllDevicesMetaData() {
    const url = `${this.backendUrls.deviceLifecycleUrl}/endpoints?include=metadata&limit=0`
    return this.httpClient.get<MetadataResponse>(url)
  }

  public getDeviceServicesInfo() {
    return this.httpClient.get<ServiceInformationResponse>(this.backendUrls.DeviceTypeInformationUrl + '/services')
  }

  public getDeviceTypeInfo() {
    return this.httpClient.get<DeviceTypeInformationResponse>(this.backendUrls.DeviceTypeInformationUrl + '/device-information')
  }

  public getLastDeviceHealthStatus(endpointIds: string, metricName: string) {
    const url = `${this.backendUrls.apiTsdBaseUrl}/applications/proficloud/time-series/last?endpointId=${endpointIds}&timeSeriesName=${metricName}`
    return this.httpClient.get<LastTrafficlightsResponse>(url)
  }
  public loadDeviceGroups() {
    const url = this.backendUrls.deviceGroupUrl + '/api/v1/groups/'
    return this.httpClient.get<ApiResponseV_1_2>(url)
  }

  public addEmptyDeviceGroup(name: string, description: string, tags: string[]) {
    const deviceGroupUrl = this.backendUrls.deviceGroupUrl + '/api/v1/groups/'
    const postData = {
      name: name,
      description: description,
      tags: tags,
    }
    return this.httpClient.post<ApiResponseV_1_2>(deviceGroupUrl, postData)
  }

  public addDeviceToGroup(group: DeviceGroup, endpointId: string, x: number, y: number) {
    const deviceGroupUrl = this.backendUrls.deviceGroupUrl + '/api/v1/groups/' + group.id + '/devices/'
    const postData = {
      endpoint_id: endpointId,
      location: {
        x: x,
        y: y,
      },
    }
    return this.httpClient.post<ApiResponseV_1_2>(deviceGroupUrl, postData)
  }

  public deleteDeviceFromGroup(group: DeviceGroup, endpointId: string) {
    const deviceGroupUrl = this.backendUrls.deviceGroupUrl + '/api/v1/groups/' + group.id + '/devices/' + endpointId + '/'
    return this.httpClient.delete<ApiResponseV_1_2>(deviceGroupUrl)
  }

  public editDeviceGroup(group: DeviceGroup, name: string, description: string, tags: string[]) {
    const deviceGroupUrl = this.backendUrls.deviceGroupUrl + '/api/v1/groups/' + group.id + '/'
    const postData = {
      name: name,
      description: description,
      tags: tags,
    }
    return this.httpClient.put<ApiResponseV_1_2>(deviceGroupUrl, postData)
  }

  public deleteDeviceGroup(group: DeviceGroup) {
    const deviceGroupUrl = this.backendUrls.deviceGroupUrl + '/api/v1/groups/' + group.id + '/'
    return this.httpClient.delete<ApiResponseV_1_2>(deviceGroupUrl)
  }

  public deleteImageGroup(group: DeviceGroup) {
    const deviceGroupUrl = this.backendUrls.deviceGroupUrl + '/api/v1/groups/' + group.id + '/image/'
    return this.httpClient.delete<ApiResponseV_1_2>(deviceGroupUrl, {
      headers: new HttpHeaders(this.proficloud.getAuthHeader()),
    })
  }

  public uploadGroupImage(group: DeviceGroup, event: { target: { files: File[] } }) {
    const file: File = event.target.files[0]
    const formData = new FormData()
    formData.append('image', file)

    const deviceGroupUrl = this.backendUrls.deviceGroupUrl + '/api/v1/groups/' + group.id + '/image/'
    return this.httpClient.post<ApiResponseV_1_2>(deviceGroupUrl, formData, {
      headers: new HttpHeaders(this.proficloud.getAuthHeader()),
    })
  }

  public loadGroupImage(group: DeviceGroup) {
    const deviceGroupUrl = this.backendUrls.deviceGroupUrl + '/api/v1/groups/' + group.id + '/image/'
    return this.httpClient.get(deviceGroupUrl, { headers: new HttpHeaders(this.proficloud.getAuthHeader()), responseType: 'blob' })
  }

  public placeGroupDevice(group: DeviceGroup, device: DeviceGroupDevice, x: number, y: number) {
    const deviceGroupUrl = this.backendUrls.deviceGroupUrl + '/api/v1/groups/' + group.id + '/devices/' + device.endpoint_id
    const postData = {
      location: {
        x: x,
        y: y,
      },
    }
    return this.httpClient.post<ApiResponseV_1_2>(deviceGroupUrl, postData)
  }
}
