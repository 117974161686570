import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { PCOverlayConfig } from '@shared/type-definitions/types'
import { sharedAnimations } from '../../shared.animations'

@Component({
  selector: 'pc-overlay',
  templateUrl: './pc-overlay.component.html',
  styleUrls: ['./pc-overlay.component.scss'],
  animations: [sharedAnimations.scaleIn, sharedAnimations.scaleOut, sharedAnimations.fadeIn],
  host: { '[id]': 'id' },
})
export class PCOverlayComponent implements OnInit {
  @Input() config: PCOverlayConfig

  @Input() id: string

  @Output() closeClick = new EventEmitter<void>()

  @Input() small = false

  @Input() large = false

  @Input() fullWidth = false

  @Input() disableAnimations = false

  constructor(public translate: TranslateService) {}

  ngOnInit(): void {
    // It is important to keep the === false in here! I want to check that the flag was set explicitly to false
    // if it is just undefined we set it to true by default
    if (this.config.closeable !== false) {
      this.config.closeable = true
    }
  }
}
