import { Injectable } from '@angular/core'
import { Device, DeviceInfo } from '@dms/interfaces/dms.interfaces'
import { HttpBaseService } from '@services/http-base.service'
import { ProficloudService } from '@services/proficloud.service'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { Subject, map } from 'rxjs'
import { ApplicationImage, ApplicationUpdate, UpdateStage } from './application.interfaces'

@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  currentApplication: ApplicationImage

  currentUpdateId: string

  currentUpdate: ApplicationUpdate

  currentUpdateStages: UpdateStage[]
  deviceId: string

  applicationUpdateDialog$: Subject<Device | false> = new Subject()

  applicationUploaded$: Subject<boolean> = new Subject()

  applicationUpdateStarted$: Subject<boolean> = new Subject()

  applicationUpdateCompleted$: Subject<{ success: boolean; message: string }> = new Subject()

  private messageEventSource: EventSourcePolyfill
  appItems: any
  timestampStage: string

  constructor(
    public proficloud: ProficloudService,
    private httpBase: HttpBaseService
  ) {}

  //////////////////////////// REST CALLS /////////////////////////////////////////////

  loadAppUpdateHistory(device: Device) {
    const url = `${this.httpBase.backendUrls.updateManagementUrl}/api/v3/devices/${device.metadata.uuid}/updates?updateType=application`
    return this.proficloud.http.get<any>(url).pipe(
      map((response: { data: { items: any } }) => {
        this.appItems = response.data.items
        return this.appItems
      })
    )
  }

  getUpdateStatusById(updateId: string) {
    const url = `${this.httpBase.backendUrls.updateManagementUrl}/api/v3/updates/${updateId}`
    return this.proficloud.http.get<any>(url).pipe(
      map((response) => {
        return response.data.statuses
      })
    )
  }
  getAppUpdateName(appUpdateId: string) {
    const url = `${this.httpBase.backendUrls.updateManagementUrl}/api/v3/applications/${appUpdateId}`
    return this.proficloud.http.get<any>(url).pipe(
      map((response) => {
        return response.data.manifest.identification.name
      })
    )
  }
  getActiveUpdate(device: DeviceInfo) {
    const url = `${this.httpBase.backendUrls.updateManagementUrl}/api/v3/devices/${device.metadata.uuid}/updates?active=true&updateType=application`
    return this.proficloud.http.get<any>(url).pipe(
      map((response) => {
        return response.data.totalItems
      })
    )
  }
}
