import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { Subject, debounceTime, tap } from 'rxjs'

@Component({
  selector: 'pc-scroll',
  templateUrl: './pc-scroll.component.html',
  styleUrl: './pc-scroll.component.scss',
})
export class PcScrollComponent {
  @Input()
  vertical: boolean = true

  @Input()
  horizontal: boolean = false

  @Input()
  autoScroll: string

  @Input()
  forceHeight: number

  @Output()
  onScrollBottom: EventEmitter<void> = new EventEmitter<void>()

  @ViewChild('container')
  container: ElementRef

  scrolled$ = new Subject<Event>()

  constructor() {
    // Fire scrolled event
    this.scrolled$
      .pipe(
        debounceTime(300),
        tap((event) => {
          this.onScroll(event.target as HTMLElement)
        })
      )
      .subscribe()
  }

  // Trigger scrolled to bottom event if we're near the bottom of the scrollable area (for infinite scrolling)
  public onScroll(element: HTMLElement) {
    if ((element.getBoundingClientRect().height + element.scrollTop) / element.scrollHeight > 0.9) {
      this.onScrollBottom.emit()
    }
  }
}
